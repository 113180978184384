/*color*/
.voilet-text {
    color: var(--voilet);
}
.white-text {
    color: var(--white);
}

.yellow-text {
    color: var(--d-yellow);
}
.grey-text {
    color: var(--d-grey);
}
.dgrey-bg {
    background-color: var(--d-grey);
}
.greyfont {
    color: var(--d-grey3);
}
.grey-bg {
    background-color: var(--grey);
}
.white-bg {
    background-color: var(--white);
}
.lwhite-bg {
    background-color: var(--l-white);
}
.greybg {
    background-color: var(--default);
}

/*margin*/
.m-auto {
    margin: auto;
}
.ml-10 {
    margin-left: 10px;
}
.ml-15 {
    margin-left: 15px;
}
.ml-40 {
    margin-left: 40px;
}
.ml-80 {
    margin-left: 80px;
}
.ml-60 {
    margin-left: 60px;
}
.mt-10 {
    margin-top: 10px !important;
}
.mt-12 {
    margin-top: 12px !important;
}
.mt-18 {
    margin-top: 18px !important;
}
.mt-20 {
    margin-top: 20px !important;
}
.mt-30 {
    margin-top: 30px !important;
}
.mt-40 {
    margin-top: 40px;
}
.mt-50 {
    margin-top: 50px;
}
.mt-45 {
    margin-top: 45px;
}
.pb-50 {
    padding-bottom: 20px;
}
.pt-10 {
    padding-top: 10px;
}
.pb-150 {
    padding-bottom: 150px;
}
.pt-100 {
    padding-top: 100px;
}
.pt-20 {
    padding-top: 20px;
}
.pd10 {
    padding: 10px !important;
}
.pd25 {
    padding: 25px;
}
.mb5 {
    margin-bottom: 5px;
}
.pd0 {
    padding: 0;
}
.pl-10 {
    padding-left: 10px;
}

/*width*/
.w65 {
    width: 65%;
}
.w80 {
    width: 80%;
}
.w100 {
    width: 100%;
}
.w120 {
    width: 120px;
}