/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap/scss/bootstrap";

/* Generated theme by Material Theme Generator */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import "~@angular/material/theming";

// Fonts
@import "https://fonts.googleapis.com/css?family=Material+Icons+Sharp";

@font-face {
	font-family: FontRegular;	
	src:url(/assets/fonts/RUPEE_FORADIAN.TTF) format('truetype');
	font-weight: normal;
	font-style: normal;
  }

  @font-face {
	font-family: FontSemibold;
	src:url(/assets/fonts/NTAILU.TTF) format('truetype');
	font-weight: normal;
	font-style: normal;
  }

  @font-face {
	font-family: FontBold;
	src:url(/assets/fonts/NTAILUB.TTF) format('truetype');
	font-weight: normal;
	font-style: normal;
  }
  

html, body { height: 100%; }
body { 
    margin: 0; 
    font-family: 'FontSemibold', sans-serif;
    min-height: 100vh; 
    overflow-y: auto;
}
p {
    font: 600 20px/1.2 'FontSemibold', sans-serif ;
    color: inherit;
}
b {
    font: 800 44px/1.2 'FontBold', sans-serif;
    color: inherit;
}

.semifont {
    font-family: 'FontSemibold', sans-serif !important;
}
.regularfont {
    font-family: 'FontRegular', sans-serif !important;
}
.boldfont {
    font-family: 'FontBold', sans-serif !important;
}
.caps {
    text-transform: uppercase;
}
.text-center {
    text-align: center;
}
.text-end {
    text-align: end;
}
.align-item-center {
    align-items: center;
}
.align-item-baseline {
    align-items: baseline;
}

.mat-stroked-button {
    border: 2px solid !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: var(--grey2);
}
.d-flex {
    display: flex;
}
::ng-deep {    
    .mat-form-field-appearance-outline .mat-form-field-infix {       
        padding: 20px 0 !important;
    }
    .mat-form-field-appearance-outline .mat-form-field-infix {
        padding: 0 !important;
    }
    .accordion-body {
        position: relative !important;
    }
}
.done-style {
    width: 20px;
    position: absolute;
    right: 7%;
}
.bborder {
    border-bottom: 1px solid var(--grey);
}
.onlyMobile {
    display: none;
}
.onlyDesktop {
    display: block;
}
.cPointer {
    cursor: pointer;
}
.mobile {
    button {
        width: 110px !important;
    }
}

table {
    width: 100%;
    tr.mat-header-row {
        background-color: var(--d-yellow);
        height: inherit !important;
    }
    th {
        font-family: 'FontBold';
        font-weight: 900;
    }
    th.mat-header-cell, td.mat-cell{
        padding: 10px !important;
        font-size: 14px !important;
    }
}
ol {
    li {
        list-style-type: auto;
        ul {
            li {               
                list-style-type: none;               
            }
        }
    }
}
.main {
    background-color: var(--l-white);
    padding: 40px 20px;
    .main-wrapper {
        padding: 20px;
        background-color: var(--white);
    }
}
.col-md-4 {
    button {
        width: 50% !important;
    } 
} 

@media (min-width:769px) and (max-width:900px) {
    .done-style{
        right: 9% !important;
        bottom: 38% !important;
    }
}

@media screen and (max-width: 768px) {
    .onlyMobile {
        display: block !important;
    }
    .onlyDesktop {
        display: none;
    }
    .col-md-4 {
        button {
            width: 30% !important;
        } 
    }
    .done-style{
        right: 12% !important;
        bottom: 38% !important;
    }
}

@media screen and (max-width:486px){
    .done-style{
        right: 12% !important;
        bottom: 38% !important;
    }
}